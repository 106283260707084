<template>
	<div class="p-reset">
		<div class="inner">
			<div class="steps">
				<div class="step" :class="{active:o.value==step.index}" v-for="(o,i) in step.list" :key="i">
					<span class="num">{{i+1}}</span>
					<span class="label">{{o.label}}</span>
				</div>
			</div>
			<div class="content">
				<div class="form" v-if="step.index==0">
					<div class="item">
						<label for="mobile" required>手机号</label>
						<div class="content">
							<v-input placeholder="请输入手机号" max-length="11" v-model="form[0].mobile" :disabled="timer.id!=-1"/>
						</div>
					</div>
					<div class="item">
						<label for="verifyCode" required>验证码</label>
						<div class="content">
							<v-input placeholder="请输入验证码" v-model="form[0].verifyCode">
								<button slot="after" @click="getCode()" :disabled="timer.id!=-1">{{timer.id==-1?'获取验证码':`${timer.value} 秒`}}</button>
							</v-input>
						</div>
					</div>
					<div class="item">
						<label></label>
						<div class="content">
							<v-button type="primary" @click="next()">下一步</v-button>
						</div>
					</div>
				</div>
				<div class="form" v-if="step.index==1">
					<div class="item">
						<label for="password" required>新密码</label>
						<div class="content">
							<v-input type="password" v-model="form[1].password"/>
						</div>
					</div>
					<div class="item">
						<label for="rePassword" required>确认密码</label>
						<div class="content">
							<v-input type="password" v-model="form[1].rePassword"/>
						</div>
					</div>
					<div class="item">
						<label></label>
						<div class="content">
							<v-button type="primary" @click="next()">下一步</v-button>
						</div>
					</div>
				</div>
				<div class="done" v-if="step.index==2">
					<div class="tip">
						<i class="ss-passport icon-success"/>
						<span>恭喜，您已重置成功！</span>
					</div>
					<div class="countdown" v-if="countdown.value">{{countdown.value}} 秒后自动跳转到登录界面</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  data() {
    return {
      step: {
        index: 0,
        list: [
          { label: "手机验证", value: 0 },
          { label: "填写新密码", value: 1 },
          { label: "重置成功", value: 2 },
        ],
      },
      timer: {
        id: -1,
        value: 120,
      },
      countdown: {
        id: -1,
        value: 5,
      },
      form: [
        {
          mobile: "",
          verifyCode: "",
        },
        {
          password: "",
          rePassword: "",
        },
      ],
    };
  },
  methods: {
    start(value = 120) {
      this.timer.value = value;
      this.timer.id = setInterval(
        () => this.timer.value-- <= 0 && this.stop(),
        1000
      );
    },
    stop() {
      clearInterval(this.timer.id);
      this.timer.value = -1;
      this.timer.id = -1;
    },
    async getCode() {
      const errors = new Errors({
        mobile: await Object.validate(this.form[0].mobile, [
          "请填写手机号",
          { regexp: /^1\d{10}$/gm, message: "手机号的格式不正确" },
        ]),
      });
      if (errors.length) {
        this.$message.warn(errors[0]);
      } else {
        try {
          await this.$apis["/重置密码/验证手机号"]({
            mobile: this.form[0].mobile,
          });
          await this.$apis["/重置密码/获取验证码"]({
            mobile: this.form[0].mobile,
          });
          this.$message.success("验证码已发送，注意查收");
          this.start();
        } catch (e) {}
      }
    },
    async next() {
      if (this.step.index == 0) {
        const errors = new Errors({
          mobile: await Object.validate(this.form[0].mobile, [
            "请填写手机号",
            { regexp: /^1\d{10}$/gm, message: "手机号的格式不正确" },
          ]),
          verifyCode: await Object.validate(
            this.form[0].verifyCode,
            "请填写验证码"
          ),
        });
        if (errors.length) {
          this.$message.warn(errors[0]);
        } else {
          try {
            await this.$apis["/重置密码/验证验证码"](this.form[0]);
            this.step.index++;
          } catch (e) {}
        }
      } else if (this.step.index == 1) {
        const errors = new Errors({
          password: await Object.validate(this.form[1].password, "请填写密码"),
          rePassword: await Object.validate(
            this.form[1].rePassword,
            (value, next) =>
              next(value == this.form[1].password, "两次输入的密码不一致")
          ),
        });
        if (errors.length) {
          this.$message.warn(errors[0]);
        } else {
          try {
            await this.$apis["/重置密码"](
              Object.assign({}, this.form[0], this.form[1])
            );
            this.step.index++;
            const stop = () => {
              clearInterval(this.countdown.id);
              this.countdown.id = -1;
              this.countdown.value = 5;
            };
            stop();
            this.countdown.id = setInterval(() => {
              if (this.countdown.value-- <= 0) {
                stop();
                location.href = this.$env.login;
              }
            }, 1000);
          } catch (e) {}
        }
      }
    },
  },
};
</script>

<style lang="less">
.p-reset {
  > .inner {
    width: 1100px;
    margin: 0 auto;
    > .steps {
      height: 42px;
      text-align: center;
      margin: 20px 0px 100px 0px;
      border-bottom: 2px solid #dddddd;
      > .step {
        width: 180px;
        padding: 10px;
        font-size: 18px;
        line-height: 20px;
        align-items: center;
        display: inline-flex;
        justify-content: center;
        border-bottom: 2px solid transparent;
        > .num {
          width: 20px;
          height: 20px;
          font-size: 18px;
          color: #ffffff;
          margin-right: 8px;
          border-radius: 50%;
          display: inline-flex;
          justify-content: center;
          background-color: #c2c2c2;
        }
        &.active {
          border-color: #41ac76;
          > .num {
            background-color: #41ac76;
          }
        }
      }
    }
    > .content {
      margin: 0px 0px 40px 0px;
      > .form {
        width: 400px;
        margin: 0px auto;
        > .item {
          display: flex;
          margin: 15px 0px;
          line-height: 24px;
          align-items: flex-start;
          > label {
            width: 80px;
            margin: 3px 10px;
            text-align: right;
            display: inline-block;
          }
          > .content {
            flex: 1;
            width: 0px;
            > .v-radio {
              line-height: 30px;
            }
            > .v-button {
              width: 264px;
            }
            > .v-input {
              > button {
                width: 97px;
                border: none;
                outline: none;
                cursor: pointer;
                color: #ffffff;
                padding: 0px 15px;
                background-color: #41ac76;
                box-shadow: 0 0 0 1px #41ac76;
                &[disabled] {
                  cursor: no-drop;
                  background-color: #c4c4c4;
                  box-shadow: 0 0 0 1px #c4c4c4;
                }
              }
            }
          }
        }
      }
      > .done {
        margin: 100px auto;
        text-align: center;
        > .tip {
          color: #41ac76;
          line-height: 30px;
          margin-bottom: 15px;
          > i.icon-success {
            margin: 10px;
            font-size: 36px;
            vertical-align: middle;
          }
          > span {
            font-size: 18px;
            vertical-align: middle;
          }
        }
        > .countdown {
          color: #999999;
          margin-left: 46px;
        }
      }
    }
  }
}
</style>